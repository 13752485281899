<template>
    <v-footer dark padless>
        <v-img light
               src="@/assets/images/footer-bg.png"
               position="center"
               :height="$vuetify.breakpoint.mobile ? 'auto' : 320">
            <v-card flat tile class="transparent white--text text-center mt-1 mt-md-5">
                <v-container fluid class="fill-height container--fluid">
                    <v-row align="start" justify="center">
                        <v-col cols="12" md="10">
                            <v-container>
                                <v-row class="text-start">
                                    <v-col cols="12" md="2">
                                        <span
                                            class="text-subtitle-2 yellow--text text--lighten-3 font-weight-light text-uppercase">
                                            Contacto
                                        </span><br/>
                                        <span
                                            class="text-start yellow--text text--lighten-3 text-caption font-weight-thin text-wrap">
                                            {{ company.address }}<br/>
                                            Teléfono(s): <br v-if="$vuetify.breakpoint.mdAndUp"/>
                                            {{ company.phone1 }},<br v-if="$vuetify.breakpoint.mdAndUp"/>
                                            {{ company.phone2 }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <v-container fluid class="fill-height container--fluid text-left">
                                            <v-row justify="start" dense :no-gutters="$vuetify.breakpoint.sm">
                                                <v-col cols="12" md="4">
                                                    <v-btn transparent
                                                           text
                                                           class="text-caption"
                                                           color="white" plain
                                                           @click="gotoSection('#about')">
                                                        <span>Quienes Somos</span>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-btn transparent
                                                           text class="text-caption"
                                                           color="white" plain
                                                           @click="gotoSection('#projects')"
                                                           @click.native="scrollToId('projects')">
                                                        <span>Portafolio</span>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-btn transparent text class="text-caption"
                                                           color="white" plain
                                                           :to="{path: '/investor'}">
                                                        <span>Inversionistas</span>
                                                    </v-btn>
                                                </v-col>
<!--                                                <v-col>
                                                    <v-btn v-for="(item, index) in itemsPrivateBank"
                                                           :key="index"
                                                           transparent
                                                           text
                                                           block
                                                           class="mx-1 my-0 text-capitalize"
                                                           color="white"
                                                           plain
                                                           :to="item.link"
                                                           style="font-size: .75em;">
                                                        <span>{{ item.title }}</span>
                                                    </v-btn>
                                                </v-col>-->
                                                <v-col cols="12" md="4">
                                                    <v-btn transparent
                                                           text
                                                           class="text-caption"
                                                           color="white" plain
                                                           :to="{path: '/sofom'}">
                                                        <span>SOFOM</span>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-btn transparent
                                                           text
                                                           class="text-caption"
                                                           color="white" plain
                                                           :to="{path: '/develop'}">
                                                        <span>Desarrollo Inmobiliario</span>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-btn transparent
                                                           text
                                                           class="text-caption"
                                                           color="white" plain
                                                           :to="{path: '/events'}">
                                                        <span>Eventos y Espectáculos</span>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-btn transparent text class="text-caption"
                                                           color="white" plain
                                                           @click="gotoSection('#contact')"
                                                           @click.native="scrollToId('contact')">
                                                        <span>Contacto</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
                <v-row align="start" justify="center">
                    <v-col cols="10">
                        <v-divider dark></v-divider>
                    </v-col>
                </v-row>
                <v-row align="start" justify="center">
                    <v-col cols="10">
                        <v-container fluid class="fill-height mt-0 pt-0">
                            <v-row align="start">
                                <v-col cols="12" md="6">
                                    <v-container fluid class="fill-height text-start mt-0 pt-0">
                                        <v-row align="start">
                                            <v-col>
                                                <div>
                                                    <v-tooltip v-for="(icon, i) in icons"
                                                               :key="i" top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                   color="primary"
                                                                   class="ml-1 ml-md-0 mr-1 mr-md-10 white--text"
                                                                   :href="icon.link"
                                                                   target="_blank"
                                                                   fab elevation="0"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                                   small>
                                                                <v-icon size="24px">{{ icon.text }}</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ icon.title }}</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col col="12" col-md="6" class="text-right">
                                    <span
                                        class="font-weight-thin text-caption yellow--text text--lighten-3 mt-0 pt-0">
                                        Desarrollado y diseñado por
                                        <a href="https://www.idoogroup.com" class="blue--text" target="_blank">
                                            <strong class="yellow--text text--lighten-2">IdooGROUP</strong>
                                        </a><br/>
                                        Copyright &copy; 2023 | Todos los derechos reservados
                                    </span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-card>
        </v-img>
    </v-footer>
</template>

<script>
    import companyService from "@/providers/CompanyService";

    export default {
        name: 'FooterComponent',
        data: () => ({
            icons: [
                {
                    title: "Facebook",
                    text: "mdi-facebook",
                    link: "https://www.facebook.com/private-equity-baja/",
                },
                {
                    title: "Twitter",
                    text: "mdi-twitter",
                    link: "https://www.twitter.com/private-equity-baja/",
                },
                {
                    title: "Instagram",
                    text: "mdi-instagram",
                    link: "https://www.instagram.com/private-equity-baja/",
                },
                {
                    title: "LinkedIn",
                    text: "mdi-linkedin",
                    link: "https://www.linkedin.com/private-equity-baja/",
                },
                {
                    title: "Youtube",
                    text: "mdi-youtube",
                    link: "https://www.youtube.com/private-equity-baja/",
                },
            ],
            itemsPrivateBank : [
                {title: "Alianza GBM", link: "/alliance-page", icon: null},
                {title: "Acceso al Mercado de Capitales y Deudas", link: "/market-page", icon: null},
                {title: "Compra Venta de Divisas y Transferencias de Pago", link: "/sale-purchase-page", icon: null},
                {title: "Fondo y Caja de Ahorro", link: "/refund", icon: null},
                {title: "Derivados Financieros", link: "/finance", icon: null},
                {title: "Plan de Pensión para el Retiro", link: "/pension", icon: null}
            ],
            company: {},
        }),
        filters: {
            formatPhone(phn, country) {
                // console.log('phn:', phn);
                if (phn === 'undefined' || phn == null || phn === '') {
                    return country;
                }
                switch (country) {
                    case "US":
                        return "USA +1 (" + phn.substring(0, 3) + ") " + phn.substring(3, 6) + "-" + phn.substring(6);
                    case "MX":
                        return "MEX +52 (" + phn.substring(0, 3) + ") " + phn.substring(3, 6) + "-" + phn.substring(6);
                    default:
                        return country;
                }
            },
            formatPhoneNumber(value) {
                if (value === null) {
                    return '';
                }
                return "(" + value.substring(0, 3) + ") " + value.substring(3, 6) + "-" + value.substring(6);
            }
        },
        methods: {
            getCompany() {
                this.map = null;
                companyService.getViewData().then(record => {
                    if (record.value !== null) {
                        this.company = record.value;
                    }
                });
            },
            gotoSection(toHash) {
                this.$router.push({path: '/homepage', hash: toHash});
            },
            scrollToId(id) {
                document.getElementById(id).scrollIntoView();
                // window.scrollBy(0, -60);
                window.scrollBy({
                    top: -60,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        },
        mounted() {
            this.getCompany();
        },
    };
</script>

<style scoped>
    .v-card {
        width: 100%;
    }
    .svg-border-waves .v-image {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3em;
        width: 100%;
        overflow: hidden;
    }
    .dark\:fill-white {
        fill: #fff
    }
    .dark\:fill-black {
        fill: #2d333e
    }
    .fill-accent-gray {
        fill: #e5e7eb
    }
    .fill-gray {
        fill: #aaa
    }
    .fill-black {
        fill: #2d333e
    }
</style>