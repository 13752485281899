<template>
    <div>
        <v-navigation-drawer
            v-model="drawer"
            app width="85%"
            temporary
            :color="color"
            dark>
            <v-list>
                <v-list-item>
                    <v-list-item-avatar>
                        <img src="@/assets/logo.png" alt="Logo"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="title">Private</v-list-item-title>
                        <v-list-item-subtitle>Inmobiliaria</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider/>
            <v-list dense>
                <v-list-item link @click="gotoSection('#banner')">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-regular text-subtitle-1">
                            Inicio
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="gotoSection('#about')"
                             @click.native="scrollToId('about')">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-regular text-subtitle-1">
                            Quiénes Somos
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{path: '/briefcase'}">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-regular text-subtitle-1">
                            Portafolio
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{path: '/investor'}">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-regular text-subtitle-1">
                            Inversionista
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-group
                    :no-action="false"
                    :value="false">
                    <template v-slot:activator>
                        <v-list-item-title class="font-weight-regular text-subtitle-1">Banca Privada</v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="(item, idx) in itemsPrivateBank"
                        :key="idx"
                        :to="item.link"
                        link>
                        <v-list-item-title class="font-weight-regular text-subtitle-2"
                                           v-text="item.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-item link :to="{path: '/sofom'}">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-regular text-subtitle-1">
                            SOFOM
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
<!--                <v-list-item link :to="{path: '/develop'}">-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title class="font-weight-regular text-subtitle-1">-->
<!--                            Desarrollo Inmobiliario-->
<!--                        </v-list-item-title>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->
<!--                <v-list-item link :to="{path: '/events'}">-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title class="font-weight-regular text-subtitle-1">-->
<!--                            Eventos y Espectáculos-->
<!--                        </v-list-item-title>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->
                <v-list-item link @click="gotoSection('#contact')"
                             @click.native="scrollToId('contact')">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-regular text-subtitle-1">
                            Contacto
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
            app
            :color="color"
            :flat="flat"
            dark
            class="px-1 pt-auto"
            height="120"
            :class="{ expand: flat }">
            <v-container fluid class="fill-height justify-center"
                         :class="$vuetify.breakpoint.smAndDown ? 'text-start' : 'text-center'" style="width: 100%">
<!--              v-if="$vuetify.breakpoint.mdAndUp"-->
                <v-row align="center" justify="center" >
                    <v-img src="@/assets/images/logo-private-transparent.png" max-width="300px" contain/>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-app-bar-nav-icon
                            @click.stop="drawer = !drawer"
                            class="mr-2"
                            v-if="isXs"/>
                        <div v-else>
                            <v-btn text link depressed @click="gotoSection('#banner')">
                                <span class="font-weight-regular text-caption">Inicio</span>
                            </v-btn>
                            <v-btn text link depressed @click="gotoSection('#about')"
                                   @click.native="scrollToId('about')">
                                <span class="font-weight-regular text-caption">Quiénes Somos</span>
                            </v-btn>
                            <v-btn text link depressed :to="{path: '/briefcase'}">
                                <span class="font-weight-regular text-caption">Portafolio</span>
                            </v-btn>
                            <v-btn text link depressed :to="{path: '/investor'}">
                                <span class="font-weight-regular text-caption">Inversionista</span>
                            </v-btn>
                            <v-menu transition="slide-y-reverse-transition" class="hidden-md-and-up" offset-y open-on-hover>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text>
                                        <span class="font-weight-regular text-caption">Banca Privada</span>
                                    </v-btn>
                                </template>
                                <v-list dark color="darkbarcolor" dense>
                                    <template v-for="(item, index) in itemsPrivateBank">
                                        <v-divider
                                            v-if="item.divider"
                                            :key="index"
                                            :inset="item.inset">
                                        </v-divider>
                                        <v-list-item
                                            v-else
                                            link
                                            :key="item.title"
                                            :to="item.link">
                                            <v-list-item-action>
                                                <v-list-item-title v-text="item.title"
                                                                   class="text-uppercase text-caption">
                                                </v-list-item-title>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-menu>
<!--                            <v-btn text link depressed :to="{path: '/sofom'}">-->
<!--                                <span class="font-weight-regular text-caption">SOFOM</span>-->
<!--                            </v-btn>-->
<!--                            <v-btn text link depressed :to="{path: '/develop'}">-->
<!--                                <span class="font-weight-regular text-caption">Desarrollo Inmobiliario</span>-->
<!--                            </v-btn>-->
<!--                            <v-btn text link depressed :to="{path: '/events'}">-->
<!--                                <span class="font-weight-regular text-caption">Eventos y Espectáculos</span>-->
<!--                            </v-btn>-->
                            <v-btn text link depressed
                                   @click="gotoSection('#contact')"
                                   @click.native="scrollToId('contact')">
                                <span class="font-weight-regular text-caption">Contacto</span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>
    </div>
</template>

<script>
    export default {
        name: 'MenuNavigationComponent',
        data: () => ({
            drawer: null,
            isXs: false,
            selectedPrivateBank: 1,
            items: [
                ["mdi-home-outline", "Inicio", "/homepage/#banner"],
                ["mdi-information-outline", "Quiénes Somos", "/homepage/#about"],
                ["mdi-codepen", "Portafolio", "/homepage/#projects"],
                ["mdi-email-outline", "Contactos", "/homepage/#contact"],
                ["mdi-bank", "Inversionista", "/investor"],
            ],
            itemsPrivateBank : [
                {title: "Alianza GBM", link: "/alliance-page", icon: null},
                {title: "Acceso al Mercado de Capitales y Deudas", link: "/market-page", icon: null},
                {title: "Compra Venta de Divisas y Transferencias de Pago", link: "/sale-purchase-page", icon: null},
                {title: "Fondo y Caja de Ahorro", link: "/refund", icon: null},
                {title: "Derivados Financieros", link: "/finance", icon: null},
                {title: "Plan de Pensión para el Retiro", link: "/pension", icon: null}
            ]
        }),
        props: {
            color: String,
            flat: Boolean,
        },
        methods: {
            onResize() {
                this.isXs = window.innerWidth < 850;
            },
            gotoSection(toHash) {
                this.$router.push({path: '/homepage', hash: toHash});
            },
            scrollToId(id) {
                document.getElementById(id).scrollIntoView();
                // window.scrollBy(0, -60);
                window.scrollBy({
                    top: -60,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        },
        watch: {
            isXs(value) {
                if (!value) {
                    if (this.drawer) {
                        this.drawer = false;
                    }
                }
            },
        },
        mounted() {
            this.onResize();
            window.addEventListener("resize", this.onResize, {passive: true});
        },
    };
</script>

<style scoped>
    /*.v-app-bar-title__content {
        display: flex;
    }*/
    .v-toolbar {
        transition: 0.5s;
    }
    .expand {
        height: 140px !important;
        padding-top: 10px;
    }
    .mytoolbar
    {
        padding: 30px;
        margin-left: 180px;
    }
</style>
