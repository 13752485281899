<template>
    <v-app id="startpage">
        <navigation :color="color" :flat="flat" />
    
        <!-- Sizes your content based upon application components -->
        <v-main class="pt-0">
            <!--style="margin: 0px; padding: 0px"-->
            
            <v-container fluid class="ma-0 pa-0">
                <!-- If using vue-router -->
                <router-view></router-view>
            </v-container>
        </v-main>
        <v-scale-transition>
            <v-btn
                fab
                v-show="fab"
                v-scroll="onScroll"
                dark
                fixed
                bottom
                right
                color="primary"
                @click="toTop">
                <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
        </v-scale-transition>
        <foot/>
    </v-app>
</template>

<script>
    import navigation from "@/layouts/app-layout/menu/MenuNavigationComponent.vue";
    import foot from "@/layouts/app-layout/footer/FooterComponent.vue";

    export default {
        name: 'Layout',
        components: {
            navigation,
            foot
        },
        data: () => ({
            fab: null,
            color: "",
            flat: null,
        }),
        watch: {
            fab(value) {
                if (value) {
                    this.color = "darkbarcolor";
                    this.flat = false;
                } else {
                    this.color = "transparent";
                    this.flat = true;
                }
            },
        },
        methods: {
            onpageshow() {
                window.scrollTo({top: 0, left: 0, behavior: "smooth"});
            },
            onScroll(e) {
                if (typeof window === "undefined") return;
                const top = window.pageYOffset || e.target.scrollTop || 0;
                this.fab = top > 60;
            },
            toTop() {
                this.$vuetify.goTo(0);
            },
        },
        created() {
            const top = window.pageYOffset || 0;
            if (top <= 60) {
                this.color = "transparent";
                this.flat = true;
            }
        },
    };
</script>

<style scoped lang="sass">
    @import '~vuetify/src/styles/generic/reset'
    // @import '~vuetify/src/styles/generic/index';
</style>

<style scoped lang="css">
    .v-main {
        /*background-image: url("~@/assets/images/bgMain.png");*/
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        /*background-color: #f4f7f5;*/
        background-color: #ffffff;
    }
    .v-timeline-item__opposite {
      flex: none;
    }
</style>